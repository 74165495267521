
import { mapStores } from "pinia"

import { AnalyticsEventPageId } from "@evercam/shared/types"
import { useAccountStore } from "@/stores/account"
import { useProjectStore } from "@/stores/project"

import SitePlannerViewer from "@/components/sitePlanner/SitePlannerViewer"

export default {
  name: "SitePlannerProject",
  meta: {
    pageId: AnalyticsEventPageId.sitePlanner,
  },
  components: { SitePlannerViewer },
  data: () => ({
    projectSitePlanner: null,
  }),
  computed: {
    ...mapStores(useProjectStore, useAccountStore),
  },
  async mounted() {
    await this.getSitePlannerData()
  },
  methods: {
    async getSitePlannerData() {
      try {
        let response = await fetch(
          this.$config.public.firebaseDbLink +
            `data/projects/planner/${this.projectStore.selectedProjectExid}.json`
        )
        this.projectSitePlanner = await response.json()
      } catch (e) {
        console.error(e)
      }
    },
  },
}
